<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      extName: process.env.VUE_APP_EXT_NAME
    }
  },
  async mounted () {
    // if (window.location.pathname !== '/hotgame') {
    this.setLoading(true)
    if (this.isMember) {
      await this.getBalanceInfo()
    }
    this.setLoading(false)
    // }
  },
  async updated () {
    // if (window.location.pathname !== '/hotgame') {
    this.setLoading(true)
    if (this.isMember) {
      await this.getBalanceInfo()
    }
    this.setLoading(false)
    // }
  },
  methods: {
    ...mapActions(['getBalanceInfo', 'setLoading'])
  },
  computed: {
    ...mapState({
      isMember: (state) => state.authModule.isMember
    })
  }
}
</script>

<style lang="less">
@import './styles/main_new.css';
@import './styles/main.css';
@import './styles/main2.css';
// VUE_APP_EXT_NAME => './styles/ag_custom/cs_'+ VUE_APP_EXT_NAME+'.css'
// @import './styles/ag_custom/cs_giant999.css';
// @import './styles/ag_custom/cs_wisdombet.css';
// @import './styles/ag_custom/cs_.css';
// @import './styles/ag_custom/cs_nv24.css';
// @import './styles/ag_custom/cs_pyn.css';
@import './styles/ag_custom/cs_ufo711.css';
// @import './styles/ag_custom/cs_tgone.css';
// @import './styles/ag_custom/cs_tgonebet.css';
// @import './styles/style.css';
// @import './styles/iconstyle.css';

// @import './styles/mobile.css';
 :root {
    --color-body-img: url(/assets/images/bg-black.jpg);
    --color-ex-img: url(/bg/bg.jpg);
    --color-body-2: #411b1b96;
    --color-body: #262424c4;
    --color-1-main: #900322;
    --color-2-main: #0000009c;
    --color-b1-main: #424242;
    --color-b2-main: #101010;
    --color-c1-main: #2b4381;
    --color-c2-main: #1f0a5a;
    --color-1-bg: #220b0b;
    --color-2-bg: #5a0303;
    --color-nav-border: #c8a042;
    --color-mq: #0c1e15;
  }

@font-face {
  font-family: 'Kanit-bold';
  src: url('assets/fonts/Kanit-Medium.ttf');
}

@font-face {
  font-family: 'Kanit';
  src: url('assets/fonts/Kanit-Light.ttf');
}

@font-face {
  font-family: 'Mitr';
  src: url('assets/fonts/Mitr-Regular.ttf');
}

.flag-icon-kr {
  background-image: url(assets/flags/kr.svg);
}
.flag-icon-jp {
  background-image: url(assets/flags/jp.svg);
}
.flag-icon-hk {
  background-image: url(assets/flags/hk.svg);
}
.flag-icon-cn {
  background-image: url(assets/flags/cn.svg);
}
.flag-icon-tw {
  background-image: url(assets/flags/tw.svg);
}
.flag-icon-sg {
  background-image: url(assets/flags/sg.svg);
}
.flag-icon-eg {
  background-image: url(assets/flags/eg.svg);
}
.flag-icon-de {
  background-image: url(assets/flags/de.svg);
}
.flag-icon-ru {
  background-image: url(assets/flags/ru.svg);
}
.flag-icon-in {
  background-image: url(assets/flags/in.svg);
}
.flag-icon-us {
  background-image: url(assets/flags/us.svg);
}
.flag-icon-my {
  background-image: url(assets/flags/my.svg);
}
.flag-icon-la {
  background-image: url(assets/flags/la.svg);
}
.flag-icon-vn {
  background-image: url(assets/flags/vn.svg);
}
.flag-icon-th {
  background-image: url(assets/flags/th.svg);
}

.tg1-icon-1 {
  background-image: url(assets/icon/teng14.png);
}
.tg1-icon-2 {
  background-image: url(assets/icon/teng15.png);
}
.tg1-icon-3 {
  background-image: url(assets/icon/teng13.png);
}
.tg1-icon-4 {
  background-image: url(assets/icon/teng16.png);
}
.tg1-icon-5 {
  background-image: url(assets/icon/teng12.png);
}
.tg1-icon-6 {
  background-image: url(assets/icon/teng11.png);
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html,
body {
  font-family: 'Kanit', sans-serif;
}
#app {
  font-family: 'Kanit', sans-serif;
  // background: linear-gradient(90deg, #7a7a7a -0.7%, #7a7a7a 113.23%);
  min-height: 100%;
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  opacity: 1;
  transition: all 0.3s;
}
.card-main {
  border-radius: 10px;
  margin: 20px;
  background: #900322;
  border: #fdfdfd 1px solid;
  height: auto;
}
.card-main h2 {
  margin-bottom: -7px;
  /* font-family: 'NotosansThai', sans-serif; */
  font-weight: 300;
  text-shadow: rgb(55, 97, 146) 1px 1px 0;
  transform: scaleX(0.85);
}
.card-sub {
  border-radius: 50px;
  margin: 20px;
  margin-top: 5px;
  background: rgba(255, 255, 255, 1);
  border: #fff 1px solid;
  height: auto;
  margin-bottom: -20px;
  z-index: 10;
  position: relative;
}
.btn-credit {
  border-radius: 50px !important;
}
.menu-grid {
  overflow: hidden;
  font-size: 0.9rem;
}
a.menu-grid.disabled {
  /* Make the disabled links grayish*/
  color: gray;
  /* And disable the pointer events */
  pointer-events: none;
}
a.menu-grid.hot {
  overflow: hidden;
  text-decoration: none;
  /* border: 1px solid #dab3b0; */
  border: 1px solid #e4e4e4;
  color: #150070;
  box-shadow: inset 0 0px 10px #95a2b547;
}

a.menu-grid {
  padding: 10px;
  display: inline-block;
  text-align: center;
  min-width: 100px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #eee;
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgba(55, 97, 146);
  text-decoration: none;
  text-shadow: #fff 0px 0px 0;
  line-height: 1.2;
}

.menu-grid svg {
  font-size: 25px;
}

a.menu-grid:hover,
a.menu-grid:active {
  color: rgb(55, 97, 146);

  background: #505050;
  border: 1px solid #e5e5e5;
}

.row .col-3 a.menu-grid,
.row .col-md-1 a.menu-grid,
.row .col-lg-1 a.menu-grid {
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 8px;
  width: 100%;
  font-size: 14px;
  min-width: 50px;
  line-height: 1.5;
}

.row .col-3 .menu-grid svg,
.row .col-md-1 .menu-grid svg {
  font-size: 30px;
}

.row .col-4 .menu-grid svg.sn-icon,
.row .col-md-1 .menu-grid svg.sn-icon {
  font-size: 35px !important;
  line-height: 0.9 !important;
}

a.menu-grid.powerball {
  line-height: 1.2 !important;
}

.row-menu-grid .row .col-4,
.row-menu-grid .row .col-6,
.row-menu-grid .row .col-3 {
  padding-right: 8px;
  padding-left: 8px;
}
.row-menu-grid {
  border-radius: 5px;
  margin-bottom: 60px;
}
.menu-grid.menu-money {
  background: #343a40;
  color: #fff;
  text-decoration: none;
  text-shadow: #222 1px 1px 0;
  border-radius: 0px;
  box-shadow: inset 10px -0px 0px #95a2b5;
  min-height: 60px;
  padding: 15px;
  padding-right: 5px;
}

.menu-grid.menu-money svg {
  color: #4035dc;
  text-shadow: #222 1px 1px 0;
}

.menu-grid.menu-money:hover,
.menu-grid.menu-money:active {
  background: #151515;
  color: #fff;
  text-shadow: #222 1px 1px 0;
  border-radius: 0px;
  box-shadow: inset 10px -0px 0px rgba(55, 97, 146);
}

.menu-grid.menu-money:hover svg,
.menu-grid.menu-money:active svg {
  color: #d80505;
  text-shadow: #222 1px 1px 0;
}
.ribbon {
  width: 66px;
  height: 85px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #0c0079;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 80px;
  padding: 2px 0;
  background-color: #900322;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 500 19px/1 'Lato', sans-serif !important;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-top-left {
  top: 5px;
  left: 8px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: 5px;
  top: 10px;
  transform: rotate(-45deg);
}
.btn-invite {
  width: 25vh;
  min-width: 130px;
  animation-name: btn-invite-flash;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}
@keyframes btn-invite-flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.text-white {
  color: white !important;
}
.animated-button1 {
  cursor: pointer;
  background: linear-gradient(-30deg, #0b0c3d 50%, #0a082b 50%);
  padding: 8px 15px;
  margin: 5px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4d4;
  font-size: 16px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#2665d9));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #2665d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button1 {
  margin-top: -10px;
  width: 100%;
}
.animated-button1:hover {
  text-decoration: none;
  color: gold;
}
.text-darkblue {
  color: #1a0079;
}
.form-fix {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.form-fix[readonly] {
  background-color: #ffffff !important;
}
.vue__time-picker {
  width: 100% !important;
}
.text-primary{
  color: #900322 !important;
}
.btn-primary{
  color: #fff !important;
  // background-color: #900322 !important;
  border-color: #dedfe0;
  background-color: var(--green) !important;
  border-color: #900322;
}
.btn-outline-primary{
  background: white;
  color: #900322;
  box-shadow: rgba(255, 255, 255, 0.5) 0 0 10px;
    // border-color: #900322 !important;
}
</style>
